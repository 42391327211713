import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { useGetTableLayout } from '../../../../hooks';
import { useSearch, useTranslate } from '../../../../../../hooks';

import { baseDateFormatting } from '../../../../../../helpers/utils';

import DefaultTableBodyCell from '../../../../../../components/table/DefaultTableBodyCell';
import { DateField, Table } from '../../../../../../components';
import ActionField from './components/ActionField';
import SearchSeaction from './components/SearchSeaction';
import LoaderCotainer from '../../../components/LoaderCotainer';
import { useApp } from '../../../../../../context';
import _ from 'lodash';
import { useListSuggestionTyoes } from './api/use-list-suggestion-types';
import { useDeleteSuggestionConfigType } from './api';

function SuggestionConf() {
  const [status, setStatus] = useState()
  const { companyId } = useApp()

  const { translate } = useTranslate();
  const navigate = useNavigate();
  const [titleSearch, setTitleSearch] = useSearch('');
  const { layout } = useGetTableLayout({ type: 1 });
  const [page, setPage] = useState(1);
  const {suggestionTypes, suggestionLoading, suggestionCount, suggestionRefetch} = useListSuggestionTyoes()
  const {deleteSuggestionConfig, isDeleting} = useDeleteSuggestionConfigType()
  const onDelete = ({ data }) =>
    deleteSuggestionConfig(data?.id).then(() => suggestionRefetch());

  //table columns config
  const columns = useMemo(
    () => [
      {
        name: 'typeId',
        label: translate('Type Id'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'roleName',
        label: translate('Role Name'),
        options: {
          customBodyRender: (value) => {
            return <DefaultTableBodyCell value={value} />;
          },
        },
      },
      {
        name: 'created_at',
        label: translate('Created At'),
        options: {
          customBodyRender: (value) => {
            return <DateField value={value} format={baseDateFormatting} />;
          },
        },
      },
      {
        name: 'updated_at',
        label: translate('Updated At'),
        options: {
          customBodyRender: (value) => {
            return <DateField value={value} format={baseDateFormatting} />;
          },
        },
      },
      {
        name: 'action',
        label: translate('Action'),
        options: {
          setCellHeaderProps: () => ({
            align: 'center',
            style: { fontWeight: '600' },
          }),
        },
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  //formating table api resoponse data
  const tableData = useMemo(
    () => {
      const res = suggestionTypes?.map((item) => {
        return {
          typeId: item?.id,
          roleName: item?.name,
          created_at: item?.created_at,
          updated_at: item?.updated_at,
          action: (
            <ActionField
              onReject={() => onDelete({ data: item })}
              isRejecting={isDeleting}
              className="justify-center"
              newCancelLayout={true}
            />
          ),
        };
      });

      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // [data, isDeleting],
  );

  const onCreate = () => {
    navigate('create', {
      state: { compEmpTypeList: "data" },
    });
  };

  return (
    <div>
      <SearchSeaction
        handleSearchTitle={setTitleSearch}
        openAddDesignation={onCreate}
        status={status}
      />
      {suggestionLoading ? (
        <LoaderCotainer />
      ) : (
        <div className="mt-4">
          <Table
            columns={columns}
            data={tableData || []}
            count={suggestionCount}
            options={{ ...layout }}
          />
        </div>
      )}

    </div>
  );
}

export default SuggestionConf;
