import { useList } from '../../../../../../../api/react-query';

export function useListSuggestionTyoes(params = {}) {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useList(
    'config/suggestion/configs',
    { ...params },
    '',
  )

  return {
    suggestionTypes: data?.data?.data,
    suggestionLoading: isLoading,
    suggestionError: error,
    suggestionRefetch:refetch,
    suggestionCount: data?.data?.totalCount
  };
}
