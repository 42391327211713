import { cloneElement, isValidElement, useRef, useState } from 'react';
import { Icons } from '../icons';
import classNames from 'classnames';
import { useClickOut } from '../../hooks';
import CheckIcon from '@mui/icons-material/Check';

/**
 * @param {[{ value: string, label: string }]} options .
 */

function Dropdown({
  options,
  Button: CustomButton,
  className,
  defaultOption,
  itemClassName,
  onSelect,
  children,
  disableSetValue,
  ListItem,
  style
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: defaultOption ? defaultOption.label : '',
    value: defaultOption ? defaultOption.value : '',
  });

  const ref = useRef();

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useClickOut(ref, closeDropdown);

  const handleSelect = (option) => {
    setSelectedOption(option);
    closeDropdown();

    onSelect(option);
  };

  return (
    <div className="relative w-auto" ref={ref}>
      {CustomButton && isValidElement(CustomButton) ? (
        cloneElement(CustomButton, {
          onClick: toggleDropdown,
          children: !disableSetValue
            ? selectedOption?.label || defaultOption?.label
            : CustomButton.props.children,
        })
      ) : (
        <button
          onClick={toggleDropdown}
          className="flex items-center rounded w-full gap-x-3 px-3 border group hover:text-primary hover:border-primary hover:bg-primary-light transition-300"
        >
          <span className={`${style ? 'py-2' : 'py-3'} max-w-44 truncate overflow-ellipsis`}>{selectedOption?.label}</span>
          {selectedOption?.value && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleSelect(defaultOption);
              }}
            >
              ×
            </span>
          )}
          <span className={`${style ? 'h-[40px]' : 'h-[48px]'} w-[1px] bg-base-gray transition-300 group-hover:bg-primary`}></span>
          <Icons name="chevron" />
        </button>
      )}

      <ul
        className={classNames(
          `flex flex-col gap-y-2 absolute z-20 w-full mt-2 py-2 bg-white rounded-lg shadow-[0px_8px_32px_#8F95B226] transition-300 transform origin-top max-h-80 overflow-y-auto ${className}`,
          {
            'opacity-100 scale-100': isOpen,
            'opacity-0 scale-50 pointer-events-none': !isOpen,
          },
        )}
      >
        <li>{children}</li>

        {options.map((option, i) => {
          return ListItem && isValidElement(ListItem) ? (
            <li
              className={classNames(
                `flex justify-between items-center p-2 cursor-pointer bg-base-gray mx-2 transition-300 whitespace-pre-line ${itemClassName}`,
              )}
            >
              {cloneElement(ListItem, {
                key: i,
                onClick: () => handleSelect(option),
              })}

              {selectedOption?.value === option?.value ? (
                <CheckIcon className="text-green-400" />
              ) : null}
            </li>
          ) : (
            <li
              key={i}
              onClick={() => handleSelect(option)}
              className={classNames(
                `flex justify-between items-center p-2 cursor-pointer bg-base-gray mx-2 transition-300 whitespace-pre-line ${itemClassName}`,
              )}
            >
              {option?.label}

              {selectedOption?.value === option?.value ? (
                <CheckIcon className="text-green-400" />
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export { Dropdown };
