import { useCreate } from '../../../../../../../api/react-query';

export function useCreateSuggestionTypes() {
  const {
    mutateAsync: createSuggestionTypes,
    isLoading: isCreating,
    error,
  } = useCreate({
    url: 'config/suggestion/configs',
  });

  return {
    createSuggestionTypes,
    isCreating,
    error,
  };
}
