import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';

import { Icons } from '../components';
import UserContext from '../context/UserContext';
import { check_permission, show_action_required } from '../common/Methodcommon';

import './i18n';
import './MainMenu.css';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  myText: {
    fontSize: 18,
    color: 'green',
  },
  itemText: {
    paddingLeft: '0.5em',
    color: 'green',
  },
};

const MainMenu = (props) => {
  // --- MAIN MENU -----

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState({
    main: '',
    child: '',
    isOpenMain: false,
  });
  const [refreshTab, setRefreshTab] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === '/company/Attendance/AttendanceList' ||
      window.location.pathname === '/company/Attendance/WeekOff' ||
      window.location.pathname === '/company/Attendance/ShiftAndSchedule' ||
      window.location.pathname === '/company/Attendance/TimeShift' ||
      window.location.pathname === '/company/Attendance/Holidays' ||
      window.location.pathname === '/company/Attendance/GeofencingLocation' ||
      window.location.pathname === '/company/Attendance/leave-allotment'
    ) {
      setRefreshTab(!refreshTab);
    }

    updateAssignedLicenseModules(menuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { userInfo, activeBU, activeUserRole, updateAssignedLicenseModules } =
    useContext(UserContext);
  const isFav = (url) => {
    if (userInfo.currentRole) {
      const fav = _.find(userInfo.currentRole.user_preferences, function (p) {
        return (
          p.preference_name === 'FAV_SCREEN' &&
          p.company_id === userInfo.currentRole.company_id
        );
      });
      if (fav) {
        if (fav.value === url) return true;
        else {
          return false;
        }
      } else {
        return false;
      }
    }
  };
  let menuItems = [
    {
      icon: null,
      title: 'Main',
      link: null,
    },
    {
      icon: <i className="las la-chart-pie" style={{ fontSize: '1.2em' }}></i>,
      title: 'Dashboard',
      link: '/company/dashboard',
      header: 'Main',
      is_fav: isFav('/company/dashboard'),
    },

    {
      icon: <i className="las la-user-cog" style={{ fontSize: '1.2em' }}></i>,
      title: 'HR Admin',
      link: '/company/HRAdmin',
      header: 'Main',
      is_fav: isFav('/company/HRAdmin'),
    },
    {
      icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
      title: 'Employee Data',
      link: '/company/EmployeeData',
      is_fav: isFav('/company/EmployeeData'),
      header: 'Main',
    },
    // {
    //   icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
    //   title: 'hire-employee',
    //   link: '/company/HireEmployee',
    //   is_fav: isFav('/company/EmployeeData'),
    //   header: 'Main',
    // },
    {
      icon: <i className="las la-shield-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Attendance',
      mainMenu: '/company/Attendance/AttendanceList',
      is_fav: isFav('/company/EmployeeData'),
      subMenu: [
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Attendance List',
          link: '/company/Attendance/AttendanceList',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Week Off',
          link: '/company/Attendance/WeekOff',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Time Shift',
          link: '/company/Attendance/time-shift',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Shift & Schedule',
          link: '/company/Attendance/shift-schedule',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'Holidays',
          link: '/company/Attendance/Holidays',
        },
        {
          icon: <i className="las la-circle" style={{ fontSize: '0.5em' }}></i>,
          title: 'geofencing',
          link: '/company/Attendance/GeofencingLocation',
        },
      ],

      header: 'Main',
    },
    {
      icon: <i className="las la-user" style={{ fontSize: '1.2em' }}></i>,
      title: 'Leave Allotment',
      link: '/company/Attendance/leave-allotment',
      is_fav: isFav('/company/Attendance/leave-allotment'),
      header: 'Main',
    },
    {
      icon: (
        <i
          className="las la-bullhorn"
          color="#8F95B2"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'Announcement',
      link: '/company/Announcement',
      is_fav: isFav('/company/Announcement'),

      header: 'Main',
    },
    {
      icon: (
        <i
          className="lab la-wpforms"
          color="#8F95B2"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'Suggestion',
      link: '/company/Suggestion',
      is_fav: isFav('/company/Suggestion'),

      header: 'Main',
    },
    // {
    //   icon: (
    //     <i
    //       className="las la-tasks"
    //       color="#8F95B2"
    //       style={{ fontSize: '1.2em' }}
    //     ></i>
    //   ),
    //   title: 'To-Do Task',
    //   link: '/company/ToDoTasks',
    //   is_fav: isFav('/company/ToDoTasks'),

    //   header: 'Main',
    // },
    {
      icon: <i className="las la-bullseye" style={{ fontSize: '1.2em' }}></i>,
      title: 'Action Required',
      link: '/company/action-required',
      is_fav: isFav('/company/action-required'),

      header: 'Main',
    },

    {
      icon: null,
      title: 'Admin Support',
      link: null,
      // header: "Main",
    },
    {
      icon: <i className="las la-pen-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Assets',
      link: '/company/Assets',
      header: 'Admin Support',
      is_fav: isFav('/company/Assets'),
    },
    {
      icon: <i className="las la-briefcase" style={{ fontSize: '1.2em' }}></i>,
      title: 'Docs Management',
      link: '/company/PROWork',
      is_fav: isFav('/company/PROWork'),

      header: 'Admin Support',
    },
    {
      icon: <i className="las la-briefcase" style={{ fontSize: '1.2em' }}></i>,
      title: 'Training',
      link: '/company/Training',
      is_fav: isFav('/company/Training'),

      header: 'Admin Support',
    },
    {
      icon: <i className="las la-file-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Letter Generation',
      link: '/company/letter-generator',
      is_fav: isFav('/company/letter-generator'),

      header: 'Admin Support',
    },
    {
      icon: null,
      title: 'Payroll',
      link: null,
    },
    {
      icon: <i className="las la-chart-bar" style={{ fontSize: '1.2em' }}></i>,
      title: 'Dashboard',
      link: '/company/Payrolldashboard',
      is_fav: isFav('/company/Payrolldashboard'),

      header: 'Payroll',
    },
    {
      icon: (
        <i
          className="las la-hand-holding-usd"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'Payroll Settlement',
      link: '/company/PayrollSettlement',
      is_fav: isFav('/company/PayrollSettlement'),

      header: 'Payroll',
    },
    {
      icon: <i className="las la-tools" style={{ fontSize: '1.2em' }}></i>,
      title: 'Payroll Setup',
      link: '/company/PayrollSetup',
      is_fav: isFav('/company/PayrollSetup'),

      header: 'Payroll',
    },
    {
      icon: (
        <i className="las la-user-shield" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Payroll Admin',
      link: '/company/PayrollAdmin',
      is_fav: isFav('/company/PayrollAdmin'),

      header: 'Payroll',
    },
    {
      icon: (
        <i
          className="las la-file-invoice-dollar"
          style={{ fontSize: '1.2em' }}
        ></i>
      ),
      title: 'FnF',
      link: '/company/FnF',
      is_fav: isFav('/company/FnF'),

      header: 'Payroll',
    },
    {
      icon: null,
      title: 'Reports',
      link: null,
    },
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Attendance Report',
      link: '/company/AttendanceReport',
      is_fav: isFav('/company/AttendanceReport'),

      header: 'Reports',
    },
    {
      icon: <i className="las la-chart-bar" style={{ fontSize: '1.2em' }}></i>,
      title: 'Payroll Report',
      link: '/company/PayrollReport',
      is_fav: isFav('/company/PayrollReport'),

      header: 'Reports',
    },
    // {
    //   icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
    //   title: 'Leave Report',
    //   link: '/company/LeaveReport',
    //   is_fav: isFav('/company/LeaveReport'),
    //
    //   header: 'Reports',
    // },

    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Accrual Report',
      link: '/company/AccrualReport',
      is_fav: isFav('/company/AccrualReport'),

      header: 'Reports',
    },
    {
      icon: null,
      title: 'Meta Report',
      link: null,
    },
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'AI Report',
      link: '/company/AIReport',
      header: 'Meta Report',
    },

    {
      icon: null,
      title: 'Super Admin',
      link: null,
    },
    {
      icon: <i className="las la-city" style={{ fontSize: '1.2em' }}></i>,
      title: 'Company',
      link: '/company/company-setup',
      is_fav: isFav('/company/company-setup'),

      header: 'Super Admin',
    },
    {
      icon: (
        <i className="las la-project-diagram" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Org Structure',
      link: '/company/organisation-structure',
      is_fav: isFav('/company/organisation-structure'),

      header: 'Super Admin',
    },
    {
      icon: (
        <i className="las la-network-wired" style={{ fontSize: '1.2em' }}></i>
      ),
      title: 'Workflow',
      link: '/company/workflows',
      is_fav: isFav('/company/workflows'),

      header: 'Super Admin',
    },
    // {
    //   icon: <i className="las la-wallet" style={{ fontSize: '1.2em' }}></i>,
    //   title: 'Billing & Credits',
    //   link: '/company/BillingAndCredits',
    //   is_fav: isFav('/company/BillingAndCredits'),
    //
    //   header: 'Super Admin',
    // },
    {
      icon: <i className="las la-file-alt" style={{ fontSize: '1.2em' }}></i>,
      title: 'Reports',
      link: '/company/report',
    },
    {
      icon: null,
      title: 'Others',
      link: null,
    },
    {
      icon: <i className="lab la-orcid" style={{ fontSize: '1.2em' }}></i>,
      title: 'Reference Id',
      link: '/company/ReferencesId',
      is_fav: isFav('/company/ReferencesId'),
      header: 'Others',
    },
    {
      icon: null,
      title: 'Performance',
      link: null,
    },
    /* {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Emp. Performance',
      link: '/company/EmployeePerfomance',
      header: 'Performance',
    }, */
    {
      icon: <i className="las la-chart-area" style={{ fontSize: '1.2em' }}></i>,
      title: 'Configuration',
      link: '/company/PerfomanceConfiguration',
      header: 'Performance',
    },
  ];

  const buildMenu = () => {
    if (userInfo?.isAdmin) {
      // if admin than now we will show only MODULES that are included in license's package
      const ASSIGNED__MODULES = _.map(activeBU?.company_assigned_modules);
      const temp_arr_modules = ASSIGNED__MODULES[0];

      if (ASSIGNED__MODULES[0]?.length > 0) {
        const filter_with_modules = _.filter(menuItems, function (mw) {
          return temp_arr_modules.includes(mw?.header);
        });

        let finalMenuList_admin = [];
        const byHeading = _.groupBy(filter_with_modules, 'header');

        _.forEach(byHeading, function (menu, header) {
          const list_admin = [
            {
              icon: null,
              title: header,
              link: null,
            },
            ...menu,
          ];

          finalMenuList_admin = [...finalMenuList_admin, ...list_admin];
        });

        return finalMenuList_admin;
      } else {
        return menuItems;
      }
    } else {
      const accessList = _.uniq(_.map(activeBU.perms, 'screen_name'));

      const ASSIGNED__MODULES = _.map(activeBU?.company_assigned_modules);
      //const ASSIGNED__SCREENS = _.map(activeBU?.company_assigned_screens);

      const temp_arr = ASSIGNED__MODULES[0];
      //const temp_arr_screens = ASSIGNED__SCREENS[0];
      if (temp_arr?.length > 0) {
        const m = _.filter(menuItems, function (mw) {
          return accessList.includes(mw.title);
        });

        const n = _.filter(m, function (mw) {
          //here we filter and includes only --MODULES-- that are in the company's license package
          return temp_arr.includes(mw?.header);
        });

        let finalMenuList = [];
        const byHeading = _.groupBy(n, 'header');

        _.forEach(byHeading, function (menu, header) {
          if (header === 'Main') {
            menu = check_permission(
              'attendance_list',
              menu,
              'sub_menus',
              userInfo,
            );
          }
          const list = [
            {
              icon: null,
              title: header,
              link: null,
            },
            ...menu,
          ];
          finalMenuList = [...finalMenuList, ...list];
        });

        return finalMenuList;
      } else {
        // this else is temporary -- should be remove

        const m = _.filter(menuItems, function (mw) {
          return accessList.includes(mw.title);
        });

        let finalMenuList = [];
        const byHeading = _.groupBy(m, 'header');

        _.forEach(byHeading, function (menu, header) {
          if (header === 'Main') {
            menu = check_permission(
              'attendance_list',
              menu,
              'sub_menus',
              userInfo,
            );
          }
          const list = [
            {
              icon: null,
              title: header,
              link: null,
            },
            ...menu,
          ];
          finalMenuList = [...finalMenuList, ...list];
        });

        return finalMenuList;
      }
    }
  };

  const { t } = useTranslation();

  return (
    <ul className="space-y-1 w-full px-5 pt-5">
      {_.map(buildMenu(), function (item) {
        return item.link ? (
          (item.title === 'Action Required' &&
            show_action_required(activeBU, activeUserRole)) ||
          item.title !== 'Action Required' ? (
            <li
              className={classNames(
                'flex items-center gap-x-4 cursor-pointer font-normal px-2 py-[10px] rounded-md transition-300',
                {
                  'text-primary bg-primary-light':
                    window.location.pathname === item.link,
                  'hover:text-secondary text-text hover:bg-gray-50':
                    window.location.pathname !== item.link,
                },
              )}
              onClick={() => {
                navigate(item.link);
                setSelectedTab((prev) => ({
                  ...prev,
                  main: item.title,
                  child: null,
                  isOpenMain: false,
                }));
              }}
            >
              {item.icon ?? null}

              {props.isHovered ? (
                <span className="flex justify-between items-center whitespace-nowrap">
                  {t(item.title)}

                  {item.subMenu ? (
                    <Icons
                      name="chevron"
                      width="14"
                      height="14"
                      viewBox={[16, 16]}
                    />
                  ) : null}
                </span>
              ) : null}
            </li>
          ) : null
        ) : item.mainMenu ? (
          <li
            className={classNames(
              'flex flex-col cursor-pointer px-2 py-[10px] rounded-md transition-300 whitespace-nowrap',
              {
                'hover:text-secondary text-text hover:bg-gray-50':
                  !item.subMenu.find(
                    (subItem) => subItem.title === selectedTab.child,
                  ),
              },
              {
                'text-primary': selectedTab.main === item.title,
              },
            )}
            key={item.title}
            onClick={() => {
              if (item.title === 'Attendance' || item.title === 'Perfomance') {
                setRefreshTab(!refreshTab);
              }

              if (!selectedTab.child) navigate(item.subMenu[0].link);

              setSelectedTab((prev) => ({
                main: item.title,
                child: item.subMenu[0].title,
                isOpenMain: !prev.isOpenMain,
              }));
            }}
          >
            <span className="flex items-center gap-x-3">
              {item.icon ?? null}
              {props.isHovered ? (
                <span className="flex justify-between items-center w-full whitespace-nowrap">
                  {t(item.title)}

                  <Icons
                    name="chevron"
                    width="14"
                    height="14"
                    viewBox={[16, 16]}
                    className={classNames('transform transition-300', {
                      'rotate-180': selectedTab.isOpenMain,
                    })}
                  />
                </span>
              ) : null}
            </span>

            {props.isHovered ? (
              <ul
                className={classNames('space-y-1 transition-300', {
                  'max-h-auto opacity-100 pt-3 z-0': selectedTab.isOpenMain,
                  'max-h-0 opacity-0 -z-10': !selectedTab.isOpenMain,
                })}
              >
                {_.map(item.subMenu, (_item, i) => (
                  <li
                    className={classNames(
                      'flex items-center gap-x-4 cursor-pointer px-2 py-[10px] rounded-md transition-300',
                      {
                        'text-primary bg-primary-light':
                          window.location.pathname === _item.link,
                        'hover:text-secondary text-text hover:bg-gray-50':
                          window.location.pathname !== _item.link,
                      },
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(_item.link);
                      setSelectedTab((prev) => ({
                        ...prev,
                        main: item.link,
                        child: _item.title,
                      }));
                    }}
                    key={i}
                  >
                    {_item.icon ?? null}
                    <span className="truncate overflow-ellipsis whitespace-nowrap">
                      {t(_item.title)}
                    </span>{' '}
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ) : (
          <li className="text-sm font-bold text-secondary uppercase px-2 py-1 tracking-widest whitespace-nowrap">
            {props.isHovered ? t(item.title) : '--'}
          </li>
        );
      })}
    </ul>
  );
};

export default withStyles(styles)(MainMenu);
