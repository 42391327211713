import { createBrowserRouter } from 'react-router-dom';
import Login from '../pages/Login/Login';
import React from 'react';
import Initializer from '../pages/Initializer/Initializer';
import ViewController from '../pages/ViewController/ViewController';
import MainLayout from '../pages/MainLayout';
import AuthRoute from '../components/AuthRoute';
import { lazyLoadWithRetry } from '../helpers/utils';
import ResetPassword from '../pages/Login/ResetPassword';
import PrivateRoute from '../components/auth/PrivateRoute';
import ForbiddenPage from '../pages/403';
import Download from '../pages/Perfomance/Configuration/Components/Kpis/pdf/download';
import Published from '../pages/Perfomance/Configuration/Components/AssignedKpi/published';
import CompanyConfigLayout from './CompanyConfigLayout';
import BaseRoute from './BaseRoute';
import OvertimeConf from '../pages/v2/company-configurtaion/pages/payroll/overtime-configuration/OvertimeConf';
import AddOvertimeConfPage from '../pages/v2/company-configurtaion/pages/payroll/overtime-configuration/components/AddOvertimeConfPage';
import SuggestionConf from '../pages/v2/company-configurtaion/pages/core-hr-pages/suggestion-configuration/SuggestionConf';
import AddSuggestionConfPage from '../pages/v2/company-configurtaion/pages/core-hr-pages/suggestion-configuration/components/AddSuggestionConfPage';

//v2 company configuration pages

//v2 configurations
const Department = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/department/Department'
    ),
);
const AddDepartmentPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/department/components/AddDepartmentPage'
    ),
);
const Designation = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/designation/Designation'
    ),
);
const AddDesignationPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/designation/components/AddDesignationPage'
    ),
);
const BusinessLocation = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/business-location/BusinessLocation'
    ),
);
const AddBusinessLocation = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/business-location/components/AddLocation'
    ),
);
const MutliFactorAuth = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/mutlifactor-auth/MutliFactorAuth'
    ),
);
const AddMfa = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/mutlifactor-auth/components/AddMfaPage'
    ),
);
const PasswordPolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/password-policy/PasswordPolicy'
    ),
);
const NotificationAction = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/notification-action/NotificationAction'
    ),
);
const NotificationsPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/notifications/NotificationsPage'
    ),
);

//v2 core hr
const EmployeeType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-type/EmployeeType'
    ),
);
const AddEmployeeTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-type/components/AddEmployeeTypePage'
    ),
);
const Suggestions = lazyLoadWithRetry(
  () => import('../pages/Suggestions/Suggestions'),
);
const EmployeeCategory = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-category/EmployeeCategory'
    ),
);

const AddEmployeeCategory = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-category/components/AddEmployeeCategory'
    ),
);

const Gender = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/gender/Gender'
    ),
);
const AddGenderPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/gender/components/AddGenderPage'
    ),
);
const BenefitsPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/benefits/BenefitsPage'
    ),
);
const AddBenefitTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/benefits/components/AddBenefitTypePage'
    ),
);
const MaritalStatus = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/martial-status/MartialStatus'
    ),
);
const AddMaritalStatusType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/martial-status/components/AddMaritalStatusType'
    ),
);
const Dependents = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/dependent-relationship-status/DependentRelationshipStatus'
    ),
);
const AddDependentType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/dependent-relationship-status/components/AddDependentType'
    ),
);
const Documents = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/document/Document'
    ),
);
const AddDocumentType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/document/components/AddDocumentType'
    ),
);
const Grade = lazyLoadWithRetry(
  () =>
    import('../pages/v2/company-configurtaion/pages/core-hr-pages/grade/Grade'),
);
const AddGradeTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/grade/components/AddGradeTypePage'
    ),
);
const ContactType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/contact-type/ContactType'
    ),
);
const AddContactType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/contact-type/components/AddContactType'
    ),
);
const TicketFrequency = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-frequency/TicketFrequency'
    ),
);
const AddTicketFrequencyType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-frequency/AddTicketFrequencyType'
    ),
);
const AssetType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/asset-type/AssetType'
    ),
);
const AddAssetTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/asset-type/components/AddAssetTypePage'
    ),
);
const AwardType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/award-type/AwardType'
    ),
);
const AddAwardTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/award-type/components/AddAwardTypePage'
    ),
);
const InsuranceType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/insurance-type/InsuranceType'
    ),
);
const AddInsuranceTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/insurance-type/components/AddInsuranceTypePage'
    ),
);
const InsurancePolicyType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/insurance-policy-type/InsurancePolicyType'
    ),
);
const AddInsurancePolicyType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/insurance-policy-type/components/AddInsurancePolicyType'
    ),
);
const TrainingType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/training-type/TrainingType'
    ),
);
const AddTrainingType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/training-type/components/AddTrainingType'
    ),
);
const SalaryConf = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/salary-configuration/SalaryConf'
    ),
);
const AddSalaryConf = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/salary-configuration/components/AddSalaryConf'
    ),
);
const EmployeeBank = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-bank-exchange/EmployeeBank'
    ),
);
const AddEmployeeBank = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/employee-bank-exchange/components/AddEmployeeBank'
    ),
);
const CompanyBank = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/company-bank-exchange/CompanyBank'
    ),
);
const AddCompanyBank = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/company-bank-exchange/components/AddCompanyBank'
    ),
);
const TicketType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-type/TicketType'
    ),
);
const AddTicketType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-type/components/AddTicketType'
    ),
);
const LeaveType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-type/LeaveType'
    ),
);
const AddLeaveType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-type/components/AddLeaveType'
    ),
);
const LeaveAdvancePolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-advance-policy/LeaveAdvancePolicy'
    ),
);
const AddLeaveAdvancePolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-advance-policy/components/AddLeaveAdvancePolicy'
    ),
);
const LeaveAccrualPolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-accrual-policy/LeaveAccrualPolicy'
    ),
);
const AddLeaveAccrualPolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-accrual-policy/components/AddLeaveAccrualPolicy'
    ),
);

const LeaveEncashmentPolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-encashment-policy/LeaveEncashmentPolicy'
    ),
);
const AddLeaveEncashmentPolicy = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/leave-encashment-policy/components/AddLeaveEncashmentPolicy'
    ),
);
const AdditionalBenefits = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/additional-type/AdditionalBenefits'
    ),
);

const AddAdditionalBenefitsPage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/additional-type/components/AddAdditionalBenefitsPage'
    ),
);
const PayrollType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/payroll-type/PayRollType'
    ),
);
const AddPayRollTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/payroll-type/components/AddPayRollTypePage'
    ),
);

const AdditionType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/addition-type/AdditionType'
    ),
);
const AddAdditionType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/addition-type/components/AddAdditionType'
    ),
);
const LoanType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/loan-type/LoanType'
    ),
);
const AddLoanTypeTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/loan-type/components/AddLoanTypeTypePage'
    ),
);
const DeductionType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/deduction-type/DeductionType'
    ),
);
const AddDeductionType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/deduction-type/components/AddDeductionType'
    ),
);
const ClaimType = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/claim-type/ClaimType'
    ),
);
const AddClaimTypePage = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/claim-type/components/AddClaimTypePage'
    ),
);
const ExitReason = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/exit-reason/ExitReason'
    ),
);
const AddExitReason = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/exit-reason/components/AddExitReason'
    ),
);
const PayoutConfiguration = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/payout-configuration/PayoutConfiguration'
    ),
);
const AddPayoutConfiguration = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/payroll/payout-configuration/components/AddPayoutConfiguration'
    ),
);
const RoleSequences = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/role-sequences/RoleSequences'
    ),
);
const AddRoleSequences = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/configurations-pages/role-sequences/components/AddRoleSequences'
    ),
);
const TimeShift2 = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/time-shift/TimeShiftList'
    ),
);

const TimeShiftList = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/time-shift/TimeShiftList'
    ),
);

const CreateTimeShift = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/time-shift/create/Create'
    ),
);

const UpdateTimeShift = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/time-shift/update/Update'
    ),
);

const HRAdmin = lazyLoadWithRetry(() => import('../pages/HRAdmin/HRAdmin'));
const EmployeeData = lazyLoadWithRetry(
  () => import('../pages/EmployeeData/EmployeeData'),
);
const AttendanceList = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/AttendanceList/AttendanceList'),
);
const WeekOff = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/WeekOff/WeekOff'),
);
const TimeShift = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/TimeShift/TimeShift'),
);
const AssignedTimeShiftList = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/time-shift/AssignedTimeShiftList'),
);
const AssignTimeShift = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/time-shift/AssignTimeShift'),
);
const ShiftAndSchedule = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/ShiftAndSchedule/ShiftAndSchedule'),
);
const ShiftAndSchedule2 = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/shift-and-schedule/ShiftAndSchedule'),
);
const SwapShift = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/Attendance/shift-and-schedule/pages/swap-shift/SwapShift'
    ),
);
const Holidays = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/Holidays/Holidays'),
);
const GeofencingLocation = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/GeofencingLocation/GeofencingLocation'),
);
const LeaveAllotment = lazyLoadWithRetry(
  () => import('../pages/v2/Attendance/leave-allotement/LeaveAllotment'),
);
const Announcement = lazyLoadWithRetry(
  () => import('../pages/Announcement/Announcement'),
);
const Assets = lazyLoadWithRetry(() => import('../pages/Assets/Assets'));
const PROWork = lazyLoadWithRetry(() => import('../pages/PROWork/PROWork'));
const Training = lazyLoadWithRetry(() => import('../pages/Training/Training'));
const Payrolldashboard = lazyLoadWithRetry(
  () => import('../pages/Payrolldashboard/Payrolldashboard'),
);
const PayrollSettlement = lazyLoadWithRetry(
  () => import('../pages/PayrollSettlement/PayrollSettlement'),
);
const PayrollSetup = lazyLoadWithRetry(
  () => import('../pages/PayrollSetup/PayrollSetup'),
);
const PayrollAdmin = lazyLoadWithRetry(
  () => import('../pages/PayrollAdmin/PayrollAdmin'),
);
const FnF = lazyLoadWithRetry(() => import('../pages/FnF/FnF'));
const AttendanceReport = lazyLoadWithRetry(
  () => import('../pages/AttendanceReport/AttendanceReport'),
);
const PayrollReport = lazyLoadWithRetry(
  () => import('../pages/PayrollReport/PayrollReport'),
);
const LeaveReport = lazyLoadWithRetry(
  () => import('../pages/LeaveReport/LeaveReport'),
);
const AIReport = lazyLoadWithRetry(
  () => import('../pages/AIReport/MetabaseReport/MetabaseReport'),
);
const AccrualReport = lazyLoadWithRetry(
  () => import('../pages/AccrualReport/AccrualReport'),
);
const CompanySetup = lazyLoadWithRetry(
  () => import('../pages/CompanySetup/CompanySetup'),
);

const CompCompanySetup = lazyLoadWithRetry(
  () => import('../pages/CompanySetup/CompCompanySetup'),
);
const OrganisationStructure = lazyLoadWithRetry(
  () => import('../pages/OrganisationStructure/OrganisationStructure'),
);
const WorkflowList = lazyLoadWithRetry(
  () => import('../pages/Workflows/WorkflowList'),
);
const BillingAndCredits = lazyLoadWithRetry(
  () => import('../pages/BillingAndCredits/BillingAndCredits'),
);
const ReferencesId = lazyLoadWithRetry(
  () => import('../pages/ReferencesId/ReferencesId'),
);
const EmployeePerfomance = lazyLoadWithRetry(
  () => import('../pages/Perfomance/EmployeePerfomance/EmployeePerfomance'),
);
const PerfomanceConfiguration = lazyLoadWithRetry(
  () => import('../pages/Perfomance/Configuration/PerfomanceConfiguration'),
);

const Dashboard = lazyLoadWithRetry(
  () => import('../pages/Dashboard/Dashboard'),
);

const HireEmployee = lazyLoadWithRetry(
  () => import('../pages/HireEmployee/HireEmployee'),
);

const NewHire = lazyLoadWithRetry(
  () => import('../pages/HireEmployee/components/NewHire/NewHire'),
);

const OrgSetup = lazyLoadWithRetry(
  () => import('../pages/CompanySetup/components/OrgSetupForm'),
);

const ActionRequired = lazyLoadWithRetry(
  () => import('../pages/ActionRequired/ActionRequired'),
);

const Notifications = lazyLoadWithRetry(
  () => import('../pages/Notification/Notification'),
);

const EmployeeOffer = lazyLoadWithRetry(
  () => import('../pages/HireEmployee/components/EmployeeOffer/EmployeeOffer'),
);

const EmployeeOfferThankYou = lazyLoadWithRetry(
  () =>
    import(
      '../pages/HireEmployee/components/EmployeeOffer/ThankYouEmployeeOffer'
    ),
);

const EmployeeAccessDenied = lazyLoadWithRetry(
  () =>
    import(
      '../pages/HireEmployee/components/EmployeeOffer/EmployeeAccessDenied'
    ),
);

const KPIView = lazyLoadWithRetry(
  () => import('../pages/Perfomance/Configuration/Components/KPIView'),
);
const CreateConfigure = lazyLoadWithRetry(
  () => import('../pages/Perfomance/Configuration/CreatePerfConfigure'),
);

const ToDoTask = lazyLoadWithRetry(() => import('../pages/ToDoTask/ToDoTask'));
const LetterGeneratorV2 = lazyLoadWithRetry(
  () => import('../pages/letter-generator/LetterGenerator'),
);

const EmployeeOnboarding = lazyLoadWithRetry(
  () =>
    import('../pages/HireEmployee/components/Onboarding/EmployeeOnboarding'),
);

const EmployeeOnboardingCongratulations = lazyLoadWithRetry(
  () => import('../pages/HireEmployee/components/Onboarding/Congratulations'),
);

const UnderMaintenancePage = lazyLoadWithRetry(
  () => import('../pages/500/UnderMaintenance'),
);

const CompOff = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/comp-off/CompOff'
    ),
);

const AddCompOff = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/comp-off/components/AddCompOffPage'
    ),
);

const AutomationTicketAccrual = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-accrual-automation/TicketAccrualAutomation'
    ),
);

const EnableAutomationTicketAccrual = lazyLoadWithRetry(
  () =>
    import(
      '../pages/v2/company-configurtaion/pages/core-hr-pages/ticket-accrual-automation/components/EnableAutomationTicketAccrual'
    ),
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseRoute />,
  },
  {
    path: '/forbidden',
    element: <ForbiddenPage />,
  },
  {
    path: '/500',
    element: <UnderMaintenancePage />,
  },
  {
    path: '/login',
    element: (
      <BaseRoute>
        <Login />
      </BaseRoute>
    ),
  },
  {
    path: '/users/reset/password',
    element: <ResetPassword />,
  },
  {
    path: '/employee-offer',
    element: <EmployeeOffer />,
  },
  {
    path: '/employee-offer/thank-you',
    element: <EmployeeOfferThankYou />,
  },
  {
    path: '/access-denied',
    element: <EmployeeAccessDenied />,
  },
  {
    path: '/company/init',
    element: <Initializer />,
  },
  {
    path: '/company/switch-view',
    element: <ViewController />,
  },
  {
    path: '/employee-onboarding',
    element: <EmployeeOnboarding />,
  },
  {
    path: '/employee-onboarding/congratulations',
    element: <EmployeeOnboardingCongratulations />,
  },
  {
    path: 'kpi-pdf-download',
    element: <Download />,
  },
  {
    path: '/company',
    element: (
      <AuthRoute>
        <MainLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <PrivateRoute
            component={<Dashboard />}
            access="Dashboard"
          ></PrivateRoute>
        ),
      },
      {
        path: 'HRAdmin',
        element: <PrivateRoute access="HR Admin" component={<HRAdmin />} />,
      },
      {
        path: 'EmployeeData',
        element: (
          <PrivateRoute access="Employee Data" component={<EmployeeData />} />
        ),
      },
      {
        path: 'action-required',
        element: (
          <PrivateRoute
            component={<ActionRequired />}
            access="Action Required"
          />
        ),
      },
      {
        path: 'Notification',
        element: (
          <PrivateRoute
            component={<Notifications />}
            access="Dashboard"
            path="Notification"
          />
        ),
      },
      {
        path: 'HireEmployee',
        element: (
          <PrivateRoute component={<HireEmployee />} access="Hire Employee" />
        ),
      },
      {
        path: 'HireEmployee/NewHire',
        element: <NewHire />,
      },
      {
        path: 'HireEmployee/NewHire/:actions/:id',
        element: <NewHire />,
      },
      {
        path: 'ToDoTasks',
        element: <ToDoTask />,
      },
      {
        path: 'Attendance/AttendanceList',
        element: (
          <PrivateRoute component={<AttendanceList />} access="Attendance" />
        ),
      },
      {
        path: 'Attendance/WeekOff',
        element: <PrivateRoute component={<WeekOff />} access="Attendance" />,
      },
      {
        path: 'Attendance/TimeShift',
        element: <PrivateRoute component={<TimeShift />} access="Attendance" />,
      },
      {
        path: 'Attendance/time-shift',
        children: [
          {
            index: true,
            element: (
              <PrivateRoute
                component={<AssignedTimeShiftList />}
                access="Attendance"
              />
            ),
          },
          {
            path: 'assign',
            element: (
              <PrivateRoute
                component={<AssignTimeShift />}
                access="Attendance"
              />
            ),
          },
        ],
      },
      {
        path: 'Attendance/ShiftAndSchedule',
        element: (
          <PrivateRoute component={<ShiftAndSchedule />} access="Attendance" />
        ),
      },
      {
        path: 'Attendance/shift-schedule',
        element: (
          <PrivateRoute component={<ShiftAndSchedule2 />} access="Attendance" />
        ),
      },
      {
        path: 'Attendance/shift-schedule/swap-shift',
        element: <PrivateRoute component={<SwapShift />} access="Attendance" />,
      },
      {
        path: 'Attendance/Holidays',
        element: <PrivateRoute component={<Holidays />} access="Attendance" />,
      },
      {
        path: 'Attendance/GeofencingLocation',
        element: (
          <PrivateRoute
            component={<GeofencingLocation />}
            access="Attendance"
          />
        ),
      },

      {
        path: 'Announcement',
        element: (
          <PrivateRoute component={<Announcement />} access="Announcement" />
        ),
      },

      {
        path: 'Suggestion',
        element: (
          <PrivateRoute component={<Suggestions />} access="Suggestion" />
        ),
      },
      {
        path: 'Assets',
        element: <PrivateRoute component={<Assets />} access="Assets" />,
      },
      {
        path: 'PROWork',
        element: (
          <PrivateRoute component={<PROWork />} access="Docs Management" />
        ),
      },
      {
        path: 'Training',
        element: <PrivateRoute component={<Training />} access="Training" />,
      },
      {
        path: 'Payrolldashboard',
        element: (
          <PrivateRoute component={<Payrolldashboard />} access="Dashboard" />
        ),
      },
      {
        path: 'PayrollSettlement',
        element: (
          <PrivateRoute
            component={<PayrollSettlement />}
            access="Payroll Settlement"
          />
        ),
      },
      {
        path: 'PayrollSetup',
        element: (
          <PrivateRoute component={<PayrollSetup />} access="Payroll Setup" />
        ),
      },
      {
        path: 'PayrollAdmin',
        element: (
          <PrivateRoute component={<PayrollAdmin />} access="Payroll Admin" />
        ),
      },
      {
        path: 'FnF',
        element: <PrivateRoute component={<FnF />} access="FnF" />,
      },
      {
        path: 'AttendanceReport',
        element: (
          <PrivateRoute
            component={<AttendanceReport />}
            access="Attendance Report"
          />
        ),
      },
      {
        path: 'PayrollReport',
        element: (
          <PrivateRoute component={<PayrollReport />} access="Payroll Report" />
        ),
      },
      {
        path: 'LeaveReport',
        element: (
          <PrivateRoute component={<LeaveReport />} access="Leave Report" />
        ),
      },
      {
        path: 'AccrualReport',
        element: (
          <PrivateRoute component={<AccrualReport />} access="Accrual Report" />
        ),
      },
      {
        path: 'AIReport',
        element: <PrivateRoute component={<AIReport />} access="AI Report" />,
      },
      {
        path: 'company-setup',
        element: <PrivateRoute component={<CompanySetup />} access="Company" />,
      },
      {
        path: 'company-setup/company',
        element: (
          <PrivateRoute component={<CompCompanySetup />} access="Company" />
        ),
      },
      {
        path: 'company-setup/organisation-setup',
        element: <PrivateRoute component={<OrgSetup />} access="Company" />,
      },
      {
        path: 'organisation-structure',
        element: (
          <PrivateRoute
            component={<OrganisationStructure />}
            access="Org Structure"
          />
        ),
      },
      {
        path: 'workflows',
        element: (
          <PrivateRoute component={<WorkflowList />} access="Workflow" />
        ),
      },
      {
        path: 'BillingAndCredits',
        element: <BillingAndCredits />,
      },
      {
        path: 'ReferencesId',
        element: (
          <PrivateRoute component={<ReferencesId />} access="Reference Id" />
        ),
      },
      {
        path: 'EmployeePerfomance',
        element: <EmployeePerfomance />,
      },
      {
        path: 'PerfomanceConfiguration',
        element: <PerfomanceConfiguration />,
      },
      {
        path: 'PerfomanceConfiguration/KPIView',
        element: <KPIView />,
      },
      { path: 'CreatePerfConfigure', element: <CreateConfigure /> },
      { path: 'letter-generator', element: <LetterGeneratorV2 /> },
      { path: 'cycle-published', element: <Published /> },
      { path: 'Attendance/leave-allotment', element: <LeaveAllotment /> },
    ],
  },
  {
    path: 'company-config',
    element: (
      <AuthRoute>
        <CompanyConfigLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: 'department',
        children: [
          { path: '', element: <Department /> },
          { path: 'create', element: <AddDepartmentPage /> },
        ],
      },
      {
        path: 'designation',
        children: [
          { path: '', element: <Designation /> },
          { path: 'create', element: <AddDesignationPage /> },
        ],
      },
      {
        path: 'multifactor-auth',
        children: [
          { path: '', element: <MutliFactorAuth /> },
          { path: 'create', element: <AddMfa /> },
        ],
      },
      {
        path: 'business-location',
        children: [
          { path: '', element: <BusinessLocation /> },
          {
            path: 'create',
            element: <AddBusinessLocation />,
          },
        ],
      },
      { path: 'password-policy', element: <PasswordPolicy /> },
      { path: 'notification-actions', element: <NotificationAction /> },
      { path: 'notifications', element: <NotificationsPage /> },
      {
        path: 'employee-type',
        children: [
          { path: '', element: <EmployeeType /> },
          { path: 'create', element: <AddEmployeeTypePage /> },
        ],
      },
      {
        path: 'employee-category',
        children: [
          { path: '', element: <EmployeeCategory /> },
          { path: 'create', element: <AddEmployeeCategory /> },
        ],
      },
      {
        path: 'gender',
        children: [
          { path: '', element: <Gender /> },
          { path: 'create', element: <AddGenderPage /> },
        ],
      },
      {
        path: 'benefits',
        children: [
          { path: '', element: <BenefitsPage /> },
          { path: 'create', element: <AddBenefitTypePage /> },
        ],
      },
      {
        path: 'marital-status',
        children: [
          { path: '', element: <MaritalStatus /> },
          { path: 'create', element: <AddMaritalStatusType /> },
        ],
      },
      {
        path: 'dependent-relationship-status',
        children: [
          { path: '', element: <Dependents /> },
          { path: 'create', element: <AddDependentType /> },
        ],
      },
      {
        path: 'document',
        children: [
          { path: '', element: <Documents /> },
          { path: 'create', element: <AddDocumentType /> },
        ],
      },
      {
        path: 'grade',
        children: [
          { path: '', element: <Grade /> },
          { path: 'create', element: <AddGradeTypePage /> },
        ],
      },
      {
        path: 'contact-type',
        children: [
          { path: '', element: <ContactType /> },
          { path: 'create', element: <AddContactType /> },
        ],
      },
      {
        path: 'ticket-frequency',
        children: [
          { path: '', element: <TicketFrequency /> },
          { path: 'create', element: <AddTicketFrequencyType /> },
        ],
      },
      {
        path: 'ticket-type',
        children: [
          { path: '', element: <TicketType /> },
          { path: 'create', element: <AddTicketType /> },
        ],
      },
      {
        path: 'asset-type',
        children: [
          { path: '', element: <AssetType /> },
          { path: 'create', element: <AddAssetTypePage /> },
        ],
      },
      {
        path: 'time-shift',
        children: [
          { path: '', element: <TimeShiftList /> },
          { path: 'create', element: <CreateTimeShift /> },
          { path: 'update/:id', element: <UpdateTimeShift /> },
        ],
      },
      {
        path: 'award-type',
        children: [
          { path: '', element: <AwardType /> },
          { path: 'create', element: <AddAwardTypePage /> },
        ],
      },
      {
        path: 'insurance-type',
        children: [
          { path: '', element: <InsuranceType /> },
          { path: 'create', element: <AddInsuranceTypePage /> },
        ],
      },
      {
        path: 'insurance-policy-type',
        children: [
          { path: '', element: <InsurancePolicyType /> },
          { path: 'create', element: <AddInsurancePolicyType /> },
        ],
      },
      {
        path: 'training-type',
        children: [
          { path: '', element: <TrainingType /> },
          { path: 'create', element: <AddTrainingType /> },
        ],
      },
      {
        path: 'salary-configuration',
        children: [
          { path: '', element: <SalaryConf /> },
          { path: 'create', element: <AddSalaryConf /> },
        ],
      },
      {
        path: 'employee-bank',
        children: [
          { path: '', element: <EmployeeBank /> },
          { path: 'create', element: <AddEmployeeBank /> },
        ],
      },
      {
        path: 'company-bank',
        children: [
          { path: '', element: <CompanyBank /> },
          { path: 'create', element: <AddCompanyBank /> },
        ],
      },
      {
        path: 'leave-type',
        children: [
          { path: '', element: <LeaveType /> },
          { path: 'create', element: <AddLeaveType /> },
        ],
      },
      {
        path: 'leave-advance-policy',
        children: [
          { path: '', element: <LeaveAdvancePolicy /> },
          { path: 'create', element: <AddLeaveAdvancePolicy /> },
        ],
      },
      {
        path: 'leave-accrual-policy',
        children: [
          { path: '', element: <LeaveAccrualPolicy /> },
          { path: 'create', element: <AddLeaveAccrualPolicy /> },
        ],
      },
      {
        path: 'leave-encashment-policy',
        children: [
          { path: '', element: <LeaveEncashmentPolicy /> },
          { path: 'create', element: <AddLeaveEncashmentPolicy /> },
        ],
      },
      {
        path: 'comp-off',
        children: [
          { path: '', element: <CompOff /> },
          { path: 'create', element: <AddCompOff /> },
        ],
      },
      {
        path: 'additional-benefits',
        children: [
          { path: '', element: <AdditionalBenefits /> },
          { path: 'create', element: <AddAdditionalBenefitsPage /> },
        ],
      },
      {
        path: 'payroll-type',
        children: [
          { path: '', element: <PayrollType /> },
          { path: 'create', element: <AddPayRollTypePage /> },
        ],
      },
      {
        path: 'loan-type',
        children: [
          { path: '', element: <LoanType /> },
          { path: 'create', element: <AddLoanTypeTypePage /> },
        ],
      },
      {
        path: 'deduction-type',
        children: [
          { path: '', element: <DeductionType /> },
          { path: 'create', element: <AddDeductionType /> },
        ],
      },
      {
        path: 'claim-type',
        children: [
          { path: '', element: <ClaimType /> },
          { path: 'create', element: <AddClaimTypePage /> },
        ],
      },
      {
        path: 'exit-reason',
        children: [
          { path: '', element: <ExitReason /> },
          { path: 'create', element: <AddExitReason /> },
        ],
      },
      {
        path: 'addition-type',
        children: [
          { path: '', element: <AdditionType /> },
          { path: 'create', element: <AddAdditionType /> },
        ],
      },
      {
        path: 'payout-configuration',
        children: [
          { path: '', element: <PayoutConfiguration /> },
          { path: 'create', element: <AddPayoutConfiguration /> },
        ],
      },
      {
        path: 'role-sequences',
        children: [
          { path: '', element: <RoleSequences /> },
          { path: 'create', element: <AddRoleSequences /> },
        ],
      },
      {
        path: 'time-shift',
        children: [
          { path: '', element: <TimeShift2 /> },
          { path: 'create', element: <div>Test</div> },
        ],
      },
      {
        path: 'ticket-accrual-automation',
        children: [
          { path: '', element: <AutomationTicketAccrual /> },
          { path: 'create', element: <EnableAutomationTicketAccrual /> },
        ],
      },
      {
        path: 'overtime-configuration',
        children: [
          { path: '', element: <OvertimeConf /> },
          { path: 'create', element: <AddOvertimeConfPage /> },
        ],
      },
      {
        path: 'suggestion-configuration',
        children: [
          { path: '', element: <SuggestionConf /> },
          { path: 'create', element: <AddSuggestionConfPage /> },
        ],
      }
    ],
  },
]);
