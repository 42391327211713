import { useList } from '../../../../../../../api/react-query';

export function useListRoles(params = {}) {
  const {
    data,
    isLoading,
    error,
    refetch
  } = useList(
    'getRoles',
    { ...params },
    '',
    'v1'
  )

  return {
    roleTypes: data?.data?.data,
    rolesLoading: isLoading,
    rolesError: error,
    rolesRefetch:refetch
  };
}
