import { useDelete } from '../../../../../../../api/react-query';

export function useDeleteSuggestionConfigType() {
  const { mutateAsync, isLoading: isDeleting } = useDelete({
    url: 'config/suggestion/configs',
  });

  return {
    deleteSuggestionConfig: mutateAsync,
    isDeleting,
  };
}
