import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

import { useTranslate, useValidation } from '../../../../../../../hooks';
import { useCreateOvertimeConfig, useCreateSuggestionTypes, useListAllowanceTypes, useListRoles, useListShiftTypes } from '../api';

import { addSuggestion } from '../validations';
import { checkData } from '../../../../utils';

import { AutoComplete, Button } from '../../../../../../../components';
import { LoadingButton } from '../../../../../../../components/buttons';
import LoaderCotainer from '../../../../components/LoaderCotainer';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddedListItem from '../../../../components/list/AddedListItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CustomToast } from '../../../../../../../common/Methodcommon';
import { colors } from '../../../../../../../helpers/colors';
import _ from 'lodash';
import { useApp } from '../../../../../../../context';

function AddSuggestionConfPage() {
  const { translate, isRTL } = useTranslate();
  const navigate = useNavigate();
  const { companyId } = useApp()
  const [openModel, closeModel] = useState(false)
  const {createSuggestionTypes, isCreating, error} = useCreateSuggestionTypes()
  const [form, setForm] = useState({
    roles: null,
    name: ""
  });
  const [addList, setAddList] = useState({ fromSuggetion: [], fromForm: [] });

  const { errors, validate } = useValidation(addSuggestion, {
    roles: form?.roles,
  });

  const { roleTypes, rolesLoading, rolesRefetch } = useListRoles();

  const wholeList = [...addList?.fromSuggetion, ...addList?.fromForm];

  const handleSave = async (e) => {
    e.preventDefault();
    if (
      addList?.fromForm?.length === 0 &&
      addList?.fromSuggetion?.length === 0
    ) {
      return;
    }
    let payload = {
      roleIds: wholeList?.map((item) => ({
        id: item?.roles?.id
      }))
    }
    createSuggestionTypes(payload).then((res) => navigate(-1))
  };
  console.log(form)
  useEffect(() => {
    if (form?.roles) {
      setForm((prevForm) => ({
        ...prevForm,
        name: form?.roles?.label || '',
      }));
    }
  }, [form?.roles]);

  const handleChange = ({ key, value }) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

console.log(addList)
  const addFromForm = async (e) => {
    e.preventDefault();
    if (await validate()) {
      try {
        checkData({ data: form, list: wholeList });

        setAddList((prev) => ({
          ...prev,
          fromForm: [...prev?.fromForm, { name: form?.name, ...form }],
        }));
        closeModel(false)
      } catch (error) {
        CustomToast('error', error?.message);
      }
    }
  };

  const deleteFromFormAdded = (suggestion, deleteIndex) => {
    setAddList((prev) => {
      let newList = [];
      newList = prev?.fromForm?.filter((item, index) => deleteIndex !== index);
      return { ...prev, fromForm: newList };
    });
  };

  const onDeleteAll = () => {
    setAddList(() => ({ fromForm: [], fromSuggetion: [] }));
  };

  return (
    <div className="bg-white overflow-y-auto">
      <span className="flex items-center gap-x-2 mb-3 h-[26px]">
        <span className="flex gap-1">
          <KeyboardBackspaceIcon
            className={classNames(
              'text-gray-400 transform cursor-pointer mr-2',
              {
                'rotate-180': isRTL,
              },
            )}
            onClick={() => navigate(-1)}
          />
          <p className="welcome-text font-bold">{translate('Add')}</p>
          <p className="welcome-text2 font-bold">{translate('Overtime Configuration')}</p>
        </span>
      </span>
      <div className="w-full add-configuration-section-card-container">
        <div className="w-[33%]">
          <form
            className="flex flex-col gap-[11px] add-configuration-section-card overflow-y-auto w-[100%] rounded-md !border-border !border-2 p-3"
          >
            <p className="text-primary text-sm tracking-[0.6px] font-semibold">
              {translate('Add Suggestion Configuration')}
            </p>
            <AutoComplete
              isLoading={rolesLoading}
              label={translate('Roles')}
              data={roleTypes?.map((item, index) => ({
                id: item?.id,
                label: item?.name,
              }))}
              onChange={(option) => {
                handleChange({ key: 'roles', value: option });
              }}
              errorText={errors?.roles}
              showError={errors?.roles}
              className={`w-full h-[56px] border-[${colors?.lightgrey}]`}
              inputProps={{
                fullWidth: true,
              }}
              value={
                roleTypes?.find((item) => item.key === form.roles) ||
                form?.roles
              }
            />
            <LoadingButton
              className="bulk-import-btn !border-solid gap-x-1 shrink-0 px-5 rounded-[4px] w-full"
              type="submit"
              onClick={addFromForm}
            >
              {translate('Add')}
            </LoadingButton>
          </form>
        </div>
        <div className="w-[33%] flex flex-col gap-[14px]">
          <div className="w-[100%] rounded-md !border-border !border-2 p-3 add-configuration-section-card ">
            <div className="w-full flex justify-between">
              <p className="text-primary text-sm tracking-[0.6px] font-semibold">
                {translate('Added')}
              </p>
              <Button
                className={`outline-btn min-w-[75px] shrink-0 px-2 pr-3 rounded-[4px] h-7 flex items-center gap-[5px]`}
                onClick={onDeleteAll}
              >
                <i className="las la-trash-alt text-error cursor-pointer !text-[16px]"></i>
                {translate('Delete All')}
              </Button>
            </div>
            <div className="overflow-y-auto flex flex-col mt-3 gap-2 mb-0 w-full added-list-section px-1">
              {addList?.fromForm?.map((item, index) => (
                <AddedListItem
                  key={item?.name}
                  value={item}
                  handleDelete={() => deleteFromFormAdded(item, index)}
                />
              ))}
              {addList?.fromForm?.length === 0 && (
                <div className="flex h-full items-center justify-center flex-col">
                  <FilterAltIcon className="!w-20 !h-20 text-gray-400" />
                  <p className="text-sm w-[80%] text-center">
                    {translate('Please add new type')}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 w-full">
            <Button
              className={`outline-btn min-w-[75px] rounded-md`}
              onClick={() => navigate(-1)}
            >
              {translate('Cancel')}
            </Button>
            <LoadingButton
              className="primary-btn min-w-[75px] rounded-md"
              onClick={handleSave}
            // isLoading={isCreating}
            >
              {translate('Save')}
            </LoadingButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSuggestionConfPage;
